<style >

</style >
<template >
	  <v-timeline-item >
			<v-container style = "min-height: 200px" >
				  <v-card

						class = "mx-auto align-center justify-center miami-card"

						width = "600"
				  >
						<v-card-item >
							  <svg id = "Layer_2" data-name = "Layer 2" xmlns = "http://www.w3.org/2000/svg"
									viewBox = "0 0 328.182 68.048" >
									<g id = "School_of_Engineering_Computer"
										  data-name = "School of Engineering Computer" >
										  <g >
												<path d = "M68.122,38.727c.315,1.335,1.365,2.115,2.535,2.115,1.005,0,2.07-.585,2.07-1.77,0-2.16-4.815-2.82-4.815-5.445,0-1.245,1.14-2.175,2.67-2.175,1.26,0,1.515.375,2.04.375.12,0,.18-.03.27-.15h.24l.255,1.905h-.255c-.42-1.08-1.38-1.77-2.415-1.77-.99,0-1.68.569-1.68,1.409,0,2.131,4.815,2.7,4.815,5.46,0,1.471-1.305,2.565-3.06,2.565-.825,0-1.875-.405-2.085-.405-.135,0-.24.075-.3.165h-.255l-.315-2.28h.285Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M80.392,39.627c-.63,1.005-1.455,1.56-2.655,1.56-1.8,0-2.52-1.71-2.52-2.895,0-1.875,1.545-3.39,3.45-3.39.945,0,1.68.375,1.68.854,0,.255-.195.45-.435.45-.735,0-.915-.915-1.755-.915-1.035,0-1.935,1.23-1.935,2.67,0,1.5.9,2.61,2.115,2.61.72,0,1.29-.33,1.83-1.08l.225.135Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M83.332,39.823c0,.705.195.899.975.899v.285h-3v-.285c.825-.045,1.02-.33,1.02-1.155v-7.185c0-.33-.06-.449-.375-.495l-.645-.09v-.255l1.785-.614h.24v4.919c.99-.585,1.71-.944,2.475-.944,1.155,0,1.665.72,1.665,2.234,0,1.365-.03,2.205-.075,3.016.03.404.255.569,1.14.569v.285h-3.24v-.285c.39,0,.675-.075.84-.24.27-.27.33-.915.33-3.149,0-1.605-.87-1.815-1.365-1.815s-1.035.24-1.77.705v3.601Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M92.663,34.903c1.83,0,3.225,1.364,3.225,3.149,0,1.771-1.395,3.135-3.18,3.135-1.86,0-3.285-1.35-3.285-3.104,0-2.07,1.68-3.18,3.24-3.18ZM92.872,40.828c.885,0,1.89-.9,1.89-2.4,0-1.71-1.05-3.165-2.31-3.165-.99,0-1.905,1.021-1.905,2.311,0,1.814,1.035,3.255,2.325,3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M100.477,34.903c1.83,0,3.225,1.364,3.225,3.149,0,1.771-1.395,3.135-3.18,3.135-1.86,0-3.285-1.35-3.285-3.104,0-2.07,1.68-3.18,3.24-3.18ZM100.687,40.828c.885,0,1.89-.9,1.89-2.4,0-1.71-1.05-3.165-2.31-3.165-.99,0-1.905,1.021-1.905,2.311,0,1.814,1.035,3.255,2.325,3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M106.717,39.823c0,.614.285.899.87.899h.255v.285h-3.375v-.285c.96,0,1.245-.285,1.245-1.005v-7.244c0-.421-.045-.556-.465-.601l-.675-.075v-.255l1.92-.614h.225v8.895Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M116.062,34.903c1.83,0,3.225,1.364,3.225,3.149,0,1.771-1.395,3.135-3.18,3.135-1.859,0-3.285-1.35-3.285-3.104,0-2.07,1.681-3.18,3.24-3.18ZM116.272,40.828c.885,0,1.89-.9,1.89-2.4,0-1.71-1.05-3.165-2.31-3.165-.99,0-1.905,1.021-1.905,2.311,0,1.814,1.035,3.255,2.325,3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M123.937,35.727h-1.62v4.11c0,.69.271.885,1.11.885h.569v.285h-3.704v-.285c.869,0,1.02-.194,1.02-1.035v-3.96h-1.11v-.255c.421-.09.78-.225,1.11-.404,0-1.245.271-2.19,1.155-3.075.734-.735,1.439-1.064,2.13-1.064.84,0,1.545.479,1.545.704,0,.12-.285.721-.405.721-.21,0-.765-.735-1.755-.735-1.08,0-1.665.855-1.665,2.31v1.141h1.515l.105.659Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M135.157,37.632h-.285c-.12-1.064-.405-1.185-1.021-1.185h-2.534v3.194c0,.69.255.825.81.825h2.31c1.051,0,1.365-.405,1.74-1.695h.271l-.24,2.235h-7.965v-.285h.42c1.2,0,1.41-.33,1.41-1.229v-6.33c0-.84-.195-1.2-1.2-1.2h-.33v-.285h7.2l.12,2.085h-.271c-.194-1.274-.569-1.545-1.109-1.545h-3.165v3.69h2.685c.54,0,.795-.255.87-1.125h.285v2.85Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M139.506,39.703c0,.825.195,1.02,1.006,1.02v.285h-3.016v-.285c.66,0,1.005-.359,1.005-.944v-3.15c0-.555-.06-.66-.449-.735l-.511-.104v-.24l1.726-.63h.239v1.17c1.006-.63,1.846-1.185,2.58-1.185,1.021,0,1.605.75,1.605,2.25,0,1.994-.105,2.385-.12,2.97-.015.405.225.6.84.6h.33v.285h-3.225v-.285c.435-.015.689-.12.854-.285.285-.284.315-1.38.315-3.375,0-1.199-.585-1.574-1.186-1.574-.479,0-1.215.42-1.995.96v3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M152.001,36.297c-.226-.015-.931-.075-1.5-.18.09.165.255.479.255.96,0,1.109-.96,2.175-2.625,2.175-.285,0-.42-.03-.69-.12-.239.255-.915.42-.915.765,0,.375.646.525,2.146.54,1.694.016,2.279.091,2.729.54.36.36.45.735.45,1.05,0,.976-1.32,2.476-4.095,2.476-1.56,0-2.31-.69-2.31-1.425,0-.87.915-1.005,1.604-1.846-.81-.074-1.425-.585-1.425-1.064,0-.69,1.005-.72,1.53-1.11-.915-.359-1.44-1.005-1.44-1.859,0-1.29,1.155-2.295,2.64-2.295.69,0,1.275.21,1.681.6.675,0,1.305-.075,1.965-.149v.944ZM148.596,44.083c1.275,0,2.475-.84,2.475-1.785,0-.555-.465-.84-1.005-.885-.39-.045-2.279-.061-2.715-.12-.42.495-.975.705-.975,1.29,0,.705.99,1.5,2.22,1.5ZM148.176,35.188c-.57,0-1.455.314-1.455,1.71,0,.885.45,2.069,1.59,2.069.87,0,1.44-.72,1.44-1.755,0-1.29-.66-2.024-1.575-2.024Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M154.476,39.868c0,.524.285.854,1.08.854v.285h-3.33v-.285c.915,0,1.245-.3,1.245-1.095v-3.015c0-.585-.074-.66-.465-.735l-.705-.135v-.24l1.95-.675h.225v5.04ZM153.862,31.453c.359,0,.659.255.659.57s-.3.569-.659.569-.646-.255-.646-.569.285-.57.646-.57Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M158.256,39.703c0,.825.195,1.02,1.006,1.02v.285h-3.016v-.285c.66,0,1.005-.359,1.005-.944v-3.15c0-.555-.06-.66-.449-.735l-.511-.104v-.24l1.726-.63h.239v1.17c1.006-.63,1.846-1.185,2.58-1.185,1.021,0,1.605.75,1.605,2.25,0,1.994-.105,2.385-.12,2.97-.015.405.225.6.84.6h.33v.285h-3.225v-.285c.435-.015.689-.12.854-.285.285-.284.315-1.38.315-3.375,0-1.199-.585-1.574-1.186-1.574-.479,0-1.215.42-1.995.96v3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M165.381,37.198c-.03.225-.06.435-.06.63,0,1.56,1.095,2.609,2.279,2.609.75,0,1.335-.3,1.905-.975l.165.18c-.63.795-1.575,1.545-2.67,1.545-1.47,0-2.61-1.335-2.61-3.074,0-1.59,1.065-3.21,2.896-3.21,2.024,0,1.979,1.455,2.475,1.949v.346h-4.38ZM168.501,36.807c-.165-.975-.766-1.619-1.485-1.619-.495,0-1.245.225-1.59,1.619h3.075Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M171.952,37.198c-.03.225-.06.435-.06.63,0,1.56,1.095,2.609,2.279,2.609.75,0,1.335-.3,1.905-.975l.165.18c-.63.795-1.575,1.545-2.67,1.545-1.47,0-2.61-1.335-2.61-3.074,0-1.59,1.065-3.21,2.896-3.21,2.024,0,1.979,1.455,2.475,1.949v.346h-4.38ZM175.072,36.807c-.165-.975-.766-1.619-1.485-1.619-.495,0-1.245.225-1.59,1.619h3.075Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M179.211,39.913c0,.615.225.81.975.81h.57v.285h-3.556v-.285c.766-.015,1.005-.33,1.005-1.125v-3.465l-1.005-.359v-.256l1.71-.6h.301v1.59h.029c.75-1.229.93-1.515,1.425-1.515.181,0,.226.015.511.135.149.061.42.12.779.226l-.359.899c-.226-.03-.511-.104-.795-.24-.165-.074-.346-.149-.525-.149-.27,0-.435.18-.585.39-.195.271-.36.525-.479.75v2.91Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M184.326,39.868c0,.524.285.854,1.08.854v.285h-3.33v-.285c.915,0,1.245-.3,1.245-1.095v-3.015c0-.585-.074-.66-.465-.735l-.705-.135v-.24l1.95-.675h.225v5.04ZM183.711,31.453c.359,0,.659.255.659.57s-.3.569-.659.569-.646-.255-.646-.569.285-.57.646-.57Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M188.106,39.703c0,.825.194,1.02,1.005,1.02v.285h-3.016v-.285c.66,0,1.005-.359,1.005-.944v-3.15c0-.555-.06-.66-.449-.735l-.511-.104v-.24l1.726-.63h.24v1.17c1.005-.63,1.845-1.185,2.579-1.185,1.021,0,1.605.75,1.605,2.25,0,1.994-.105,2.385-.12,2.97-.015.405.225.6.84.6h.33v.285h-3.225v-.285c.435-.015.689-.12.854-.285.285-.284.315-1.38.315-3.375,0-1.199-.585-1.574-1.186-1.574-.479,0-1.215.42-1.994.96v3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M200.601,36.297c-.226-.015-.931-.075-1.5-.18.09.165.255.479.255.96,0,1.109-.96,2.175-2.625,2.175-.285,0-.42-.03-.69-.12-.239.255-.915.42-.915.765,0,.375.646.525,2.146.54,1.694.016,2.279.091,2.729.54.36.36.45.735.45,1.05,0,.976-1.32,2.476-4.095,2.476-1.56,0-2.31-.69-2.31-1.425,0-.87.915-1.005,1.604-1.846-.81-.074-1.425-.585-1.425-1.064,0-.69,1.005-.72,1.53-1.11-.915-.359-1.44-1.005-1.44-1.859,0-1.29,1.155-2.295,2.64-2.295.69,0,1.275.21,1.681.6.675,0,1.305-.075,1.965-.149v.944ZM197.196,44.083c1.275,0,2.475-.84,2.475-1.785,0-.555-.465-.84-1.005-.885-.39-.045-2.279-.061-2.715-.12-.42.495-.975.705-.975,1.29,0,.705.99,1.5,2.22,1.5ZM196.776,35.188c-.57,0-1.455.314-1.455,1.71,0,.885.45,2.069,1.59,2.069.87,0,1.44-.72,1.44-1.755,0-1.29-.66-2.024-1.575-2.024Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M215.465,34.738v.27c-.84,0-1.064.075-1.274.285-.3.3-1.05,1.845-2.25,3.24.825,1.274,1.08,2.175,1.8,2.175.3,0,.675-.165,1.17-.51l.135.239c-.645.511-1.274.78-1.845.78-.93,0-1.185-.345-2.01-1.86-1.11,1.186-2.355,1.86-3.42,1.86-1.365,0-2.43-1.08-2.43-2.475,0-1.23.734-2.025,2.625-2.79-.646-.555-1.065-1.305-1.065-1.95,0-1.05.811-1.74,2.04-1.74,1.11,0,1.905.601,1.905,1.455,0,.69-.601,1.365-1.695,1.905.975.66,1.89,1.575,2.61,2.655.704-.886,1.874-2.565,1.874-2.971,0-.21-.164-.3-.824-.3v-.27h2.654ZM208.386,40.467c.87,0,1.994-.585,2.64-1.365-.66-1.109-1.59-2.1-2.85-2.97-1.141.795-1.605,1.5-1.605,2.43,0,1.141.735,1.905,1.815,1.905ZM208.835,32.563c-.614,0-.989.42-.989,1.08,0,.63.375,1.244,1.109,1.8.69-.405.976-.87.976-1.62,0-.765-.436-1.26-1.096-1.26Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M221.871,36.868l-.87,2.354c-.105.285-.3.735-.3.945,0,.405.39.555,1.215.555h.239v.285h-3.465v-.285c.976-.015,1.141-.18,1.62-1.484l2.73-7.455-.136-.33h1.306l3.135,8.325c.3.795.615.944,1.364.944v.285h-4.124v-.285h.39c.855,0,1.17-.09,1.17-.51,0-.24-.104-.465-.165-.615l-1.005-2.729h-3.104ZM223.4,32.712l-1.274,3.495h2.595l-1.32-3.495Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M231.036,43.243c0,.75.375.975,1.005.975h.375v.285h-3.54v-.285h.15c.765,0,1.005-.255,1.005-1.11v-6.614c0-.285-.061-.405-.42-.495l-.48-.12v-.285l1.605-.989h.3v1.439c.93-.705,1.725-1.095,2.325-1.095,1.17,0,2.22,1.319,2.22,2.79,0,1.814-1.47,3.375-3.18,3.375-.405,0-.795-.091-1.365-.301v2.431ZM231.036,39.837c.54.479,1.215.81,1.77.81,1.021,0,1.771-1.005,1.771-2.234,0-1.98-1.17-2.73-1.965-2.73-.466,0-1.035.271-1.575.735v3.42Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M238.536,43.243c0,.75.375.975,1.005.975h.375v.285h-3.54v-.285h.15c.765,0,1.005-.255,1.005-1.11v-6.614c0-.285-.061-.405-.42-.495l-.48-.12v-.285l1.605-.989h.3v1.439c.93-.705,1.725-1.095,2.325-1.095,1.17,0,2.22,1.319,2.22,2.79,0,1.814-1.47,3.375-3.18,3.375-.405,0-.795-.091-1.365-.301v2.431ZM238.536,39.837c.54.479,1.215.81,1.77.81,1.021,0,1.771-1.005,1.771-2.234,0-1.98-1.17-2.73-1.965-2.73-.466,0-1.035.271-1.575.735v3.42Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M246.125,39.823c0,.614.285.899.87.899h.255v.285h-3.375v-.285c.96,0,1.245-.285,1.245-1.005v-7.244c0-.421-.045-.556-.465-.601l-.675-.075v-.255l1.92-.614h.225v8.895Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M249.905,39.868c0,.524.285.854,1.08.854v.285h-3.33v-.285c.915,0,1.245-.3,1.245-1.095v-3.015c0-.585-.075-.66-.465-.735l-.705-.135v-.24l1.95-.675h.225v5.04ZM249.29,31.453c.359,0,.659.255.659.57s-.3.569-.659.569-.646-.255-.646-.569.285-.57.646-.57Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M252.996,37.198c-.03.225-.06.435-.06.63,0,1.56,1.095,2.609,2.279,2.609.75,0,1.335-.3,1.905-.975l.165.18c-.63.795-1.575,1.545-2.67,1.545-1.47,0-2.61-1.335-2.61-3.074,0-1.59,1.065-3.21,2.896-3.21,2.024,0,1.979,1.455,2.475,1.949v.346h-4.38ZM256.116,36.807c-.165-.975-.766-1.619-1.485-1.619-.495,0-1.245.225-1.59,1.619h3.075Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M263.225,32.278c0-.285-.105-.36-.585-.42l-.495-.061v-.255l2.085-.614v8.804c0,.511.314.615.734.495l.36-.104v.314l-1.92.75h-.18v-1.064c-.976.705-1.755,1.064-2.325,1.064-1.335,0-2.4-1.29-2.4-2.895,0-1.875,1.426-3.39,3.255-3.39.45,0,1.065.12,1.471.285v-2.91ZM263.225,36.432c-.54-.795-1.08-1.125-1.785-1.125-1.17,0-1.935,1.065-1.935,2.476,0,1.5.869,2.76,1.949,2.76.465,0,1.11-.285,1.771-.75v-3.36Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M270.47,38.727c.314,1.335,1.364,2.115,2.534,2.115,1.005,0,2.07-.585,2.07-1.77,0-2.16-4.814-2.82-4.814-5.445,0-1.245,1.14-2.175,2.67-2.175,1.26,0,1.515.375,2.039.375.12,0,.181-.03.271-.15h.24l.255,1.905h-.255c-.42-1.08-1.38-1.77-2.415-1.77-.99,0-1.68.569-1.68,1.409,0,2.131,4.814,2.7,4.814,5.46,0,1.471-1.305,2.565-3.06,2.565-.825,0-1.875-.405-2.085-.405-.136,0-.24.075-.301.165h-.255l-.314-2.28h.285Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M282.74,39.627c-.631,1.005-1.455,1.56-2.655,1.56-1.8,0-2.52-1.71-2.52-2.895,0-1.875,1.545-3.39,3.449-3.39.945,0,1.68.375,1.68.854,0,.255-.194.45-.435.45-.735,0-.915-.915-1.755-.915-1.035,0-1.935,1.23-1.935,2.67,0,1.5.899,2.61,2.114,2.61.721,0,1.29-.33,1.83-1.08l.226.135Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M285.799,39.868c0,.524.285.854,1.08.854v.285h-3.33v-.285c.915,0,1.245-.3,1.245-1.095v-3.015c0-.585-.075-.66-.465-.735l-.705-.135v-.24l1.95-.675h.225v5.04ZM285.185,31.453c.359,0,.659.255.659.57s-.3.569-.659.569-.646-.255-.646-.569.285-.57.646-.57Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M288.889,37.198c-.03.225-.06.435-.06.63,0,1.56,1.095,2.609,2.279,2.609.75,0,1.335-.3,1.905-.975l.165.18c-.63.795-1.575,1.545-2.67,1.545-1.47,0-2.61-1.335-2.61-3.074,0-1.59,1.065-3.21,2.896-3.21,2.024,0,1.979,1.455,2.475,1.949v.346h-4.38ZM292.009,36.807c-.165-.975-.766-1.619-1.485-1.619-.495,0-1.245.225-1.59,1.619h3.075Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M296.149,39.703c0,.825.195,1.02,1.006,1.02v.285h-3.016v-.285c.66,0,1.005-.359,1.005-.944v-3.15c0-.555-.06-.66-.449-.735l-.511-.104v-.24l1.726-.63h.239v1.17c1.006-.63,1.846-1.185,2.58-1.185,1.021,0,1.605.75,1.605,2.25,0,1.994-.105,2.385-.12,2.97-.015.405.225.6.84.6h.33v.285h-3.225v-.285c.435-.015.689-.12.854-.285.285-.284.315-1.38.315-3.375,0-1.199-.585-1.574-1.186-1.574-.479,0-1.215.42-1.995.96v3.255Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M307.429,39.627c-.631,1.005-1.455,1.56-2.655,1.56-1.8,0-2.52-1.71-2.52-2.895,0-1.875,1.545-3.39,3.449-3.39.945,0,1.68.375,1.68.854,0,.255-.194.45-.435.45-.735,0-.915-.915-1.755-.915-1.035,0-1.935,1.23-1.935,2.67,0,1.5.899,2.61,2.114,2.61.721,0,1.29-.33,1.83-1.08l.226.135Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M309.379,37.198c-.03.225-.06.435-.06.63,0,1.56,1.095,2.609,2.279,2.609.75,0,1.335-.3,1.905-.975l.165.18c-.63.795-1.575,1.545-2.67,1.545-1.47,0-2.61-1.335-2.61-3.074,0-1.59,1.065-3.21,2.896-3.21,2.024,0,1.979,1.455,2.475,1.949v.346h-4.38ZM312.499,36.807c-.165-.975-.766-1.619-1.485-1.619-.495,0-1.245.225-1.59,1.619h3.075Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
										  </g >
										  <g >
												<path d = "M73.793,60.613c-.267.673-.799,1.737-1.135,2.256-.434.111-1.387.35-2.438.35-2.423,0-4.202-1.4-4.202-4.006,0-3.39,2.704-5.52,5.967-5.52,1.219,0,2.059.295,2.423.421-.028.729-.084,1.513-.14,2.283l-.462.042c-.196-1.695-.924-2.186-2.129-2.186-2.143,0-3.838,2.255-3.838,4.902,0,2.102.953,3.502,2.521,3.502,1.191,0,2.045-.826,2.998-2.241l.435.196Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M74.601,60.921c0-1.835,1.008-3.18,2.101-3.754.56-.295,1.177-.49,1.723-.49,1.303,0,2.003.854,2.003,2.213,0,1.219-.49,2.535-1.499,3.39-.602.519-1.471.896-2.311.896-1.555,0-2.017-1.162-2.017-2.255ZM77.542,62.504c.616-.253,1.303-1.737,1.303-3.656-.014-1.093-.406-1.583-.938-1.583-.126,0-.28.028-.406.084-.434.196-1.316,1.373-1.316,3.614,0,1.064.434,1.61.966,1.61.168,0,.266-.027.392-.069Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<path d = "M91.35,61.734c-.854.966-1.821,1.442-2.241,1.442-.406,0-.602-.336-.35-1.415l.743-3.151c.14-.574.084-.826-.084-.826-.518,0-1.933,1.61-2.423,2.507-.182.771-.392,1.724-.56,2.605-.504.042-1.037.154-1.415.28l-.098-.126c.336-1.4.672-2.773.966-4.09.182-.854.168-1.177-.056-1.177-.546,0-1.989,1.681-2.409,2.521-.21.686-.434,1.694-.616,2.591-.476.042-.995.154-1.387.28l-.112-.126c.42-1.541.771-3.04,1.121-4.637.098-.406.084-.532-.014-.532-.154,0-.617.336-.911.603l-.182-.378c.869-.896,1.765-1.429,2.241-1.429.434,0,.49.518.21,1.667l-.21.896h.028c.938-1.275,1.835-1.976,2.312-2.256.35-.21.644-.308.938-.308.392,0,.84.252.448,2.073l-.098.434h.028c.939-1.246,1.807-1.919,2.326-2.213.364-.21.63-.294.91-.294.35,0,.854.195.406,2.087l-.63,2.745c-.084.351-.042.448.042.448.126,0,.561-.294.883-.603l.196.379Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<g >
													  <path d = "M95.056,56.845c.434-.126.854-.168,1.275-.168,1.471,0,2.059.728,2.059,2.045,0,.868-.393,1.961-1.177,2.829-.687.771-1.737,1.401-2.689,1.625-.238,0-.476-.028-.756-.126l-.35,1.821c-.14.729-.042.854.644.938l.322.027-.07.435-3.614.168.098-.42c.827-.168,1.009-.322,1.191-1.163.504-2.395.994-4.734,1.471-7.031-.435.14-.799.308-1.107.476l-.126-.405c.434-.295.911-.505,1.359-.687l.154-.616c.309-.322.967-.743,1.415-.953l.154.099-.252,1.106ZM93.991,62.083c.14.196.476.463.756.448.854,0,2.059-1.933,2.059-3.697,0-1.177-.574-1.472-1.232-1.472-.225,0-.448.042-.659.099l-.924,4.622Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M105.824,61.664c-.911,1.022-1.807,1.513-2.283,1.513-.406,0-.56-.42-.322-1.457.07-.35.14-.658.21-.994h-.028c-.826,1.106-1.653,1.793-2.157,2.115-.336.224-.714.336-1.037.336-.406,0-.91-.266-.518-1.947.21-.924.434-1.849.658-2.801.098-.406.098-.574-.014-.574s-.504.308-.896.616l-.168-.393c.869-.841,1.807-1.4,2.297-1.4.378,0,.477.322.238,1.358-.224,1.009-.462,2.073-.701,3.082-.154.658-.084.896.126.896.686,0,2.003-1.695,2.381-2.396.196-.812.393-1.695.575-2.563l1.345-.378h.168c-.364,1.442-.714,2.955-1.051,4.664-.084.462-.07.589.028.589.126,0,.588-.295.938-.645l.21.378Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M110.596,57.461l-1.33.07-.757,3.655c-.14.645-.07.827.07.827.21,0,.84-.421,1.19-.757l.21.336c-.504.673-1.695,1.583-2.465,1.583-.448,0-.743-.462-.435-1.933l.798-3.712h-1.022l-.056-.141.378-.49h.84l.308-1.303,1.43-.967.153.112-.49,2.157h1.569l-.393.561Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<g >
													  <path d = "M115.65,61.482c-1.246,1.316-2.311,1.694-3.18,1.694-.756,0-1.652-.42-1.652-2.087,0-1.303.588-2.731,1.652-3.558.574-.448,1.457-.854,2.157-.854.896,0,1.373.546,1.373,1.177,0,1.414-2.255,2.129-3.643,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.561,0,1.345-.406,2.018-1.05l.266.364ZM113.563,57.448c-.336.21-.938.938-1.148,2.409,1.681-.49,2.228-1.093,2.228-1.808,0-.532-.337-.756-.589-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M116.515,58.106c.784-.827,1.695-1.429,2.171-1.429.406,0,.49.518.253,1.624l-.127.645h.057c.672-1.288,1.429-2.269,2.031-2.269.252,0,.504.14.644.364.154.224.127.504-.042.77-.153.238-.294.406-.448.519-.126.099-.237.099-.336.015-.182-.169-.364-.253-.532-.253-.154,0-.322.084-.616.49-.336.477-.715,1.135-.868,1.611-.183.812-.393,1.877-.532,2.703-.448.042-1.009.154-1.387.28l-.099-.126c.351-1.471.687-3.012.967-4.511.084-.462.07-.644-.028-.644-.126,0-.603.279-.91.574l-.196-.364Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<path d = "M125.028,60.123c.141.98.757,2.563,2.241,2.563,1.009,0,1.583-.729,1.583-1.639,0-.715-.42-1.274-1.177-1.863l-.42-.308c-.784-.631-1.625-1.415-1.625-2.563,0-1.583,1.261-2.62,2.97-2.62.532,0,1.036.099,1.499.267.252.084.462.182.588.224-.056.561-.056,1.289-.07,2.073l-.462.042c-.112-.938-.477-2.087-1.681-2.087-.938,0-1.429.784-1.429,1.513,0,.742.448,1.205,1.19,1.751l.42.309c.967.7,1.723,1.457,1.723,2.661,0,1.723-1.498,2.773-3.264,2.773-1.162,0-2.143-.49-2.507-.868,0-.322,0-1.093-.028-2.171l.448-.057Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<g >
													  <path d = "M135.782,61.593c-.924,1.037-2.017,1.583-2.885,1.583-1.037,0-1.653-.729-1.653-2.087,0-1.177.519-2.521,1.499-3.376.603-.532,1.597-1.036,2.339-1.036.658,0,1.19.336,1.19.771.015.252-.153.56-.546.784-.14.098-.28.084-.378-.07-.364-.532-.715-.784-1.093-.784-.351,0-.631.28-.868.756-.322.603-.561,1.569-.561,2.578s.35,1.456.925,1.456c.644,0,1.456-.616,1.793-.952l.237.378Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M139.687,61.692c-.798.896-1.863,1.484-2.312,1.484-.364,0-.63-.266-.336-1.457l.784-3.306c.07-.336.084-.476-.014-.476-.112,0-.519.237-.883.532l-.182-.364c.925-.869,1.933-1.429,2.396-1.429.364,0,.42.504.224,1.288l-.854,3.46c-.084.378-.056.519.042.519.141,0,.532-.267.925-.589l.21.337ZM139.939,54.688c0,.519-.406.98-.952.98-.435,0-.7-.294-.7-.771-.015-.406.322-.967.938-.967.476,0,.714.351.714.757Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<g >
													  <path d = "M145.288,61.482c-1.247,1.316-2.312,1.694-3.18,1.694-.757,0-1.653-.42-1.653-2.087,0-1.303.589-2.731,1.653-3.558.574-.448,1.456-.854,2.157-.854.896,0,1.372.546,1.372,1.177,0,1.414-2.255,2.129-3.642,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.56,0,1.345-.406,2.017-1.05l.267.364ZM143.202,57.448c-.337.21-.938.938-1.149,2.409,1.682-.49,2.228-1.093,2.228-1.808,0-.532-.336-.756-.588-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M152.75,61.734c-.98,1.022-1.807,1.442-2.255,1.442-.378,0-.616-.322-.35-1.484.237-.995.476-1.989.7-2.984.182-.742.111-.924-.07-.924-.519,0-1.919,1.555-2.479,2.549-.196.729-.406,1.667-.574,2.55-.477.056-1.022.168-1.387.294l-.098-.126c.35-1.457.728-3.025,1.078-4.622.098-.435.084-.547-.014-.547-.126,0-.589.28-.896.589l-.196-.393c.896-.91,1.765-1.4,2.241-1.4.364,0,.504.434.224,1.694l-.21.883h.028c.771-1.051,1.625-1.779,2.228-2.186.392-.252.771-.392,1.078-.392.435,0,.854.237.435,2.101-.21.869-.406,1.766-.616,2.662-.084.35-.07.504.042.504.111,0,.518-.267.868-.603l.224.393Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<g >
													  <path d = "M158.097,61.593c-.925,1.037-2.018,1.583-2.886,1.583-1.036,0-1.652-.729-1.652-2.087,0-1.177.518-2.521,1.498-3.376.603-.532,1.597-1.036,2.34-1.036.658,0,1.19.336,1.19.771.014.252-.154.56-.546.784-.141.098-.28.084-.379-.07-.364-.532-.714-.784-1.093-.784-.35,0-.63.28-.868.756-.322.603-.561,1.569-.561,2.578s.351,1.456.925,1.456c.645,0,1.457-.616,1.793-.952l.238.378Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M163.739,61.482c-1.246,1.316-2.311,1.694-3.18,1.694-.756,0-1.652-.42-1.652-2.087,0-1.303.588-2.731,1.652-3.558.574-.448,1.457-.854,2.157-.854.896,0,1.373.546,1.373,1.177,0,1.414-2.255,2.129-3.643,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.561,0,1.345-.406,2.018-1.05l.266.364ZM161.652,57.448c-.336.21-.938.938-1.148,2.409,1.681-.49,2.228-1.093,2.228-1.808,0-.532-.337-.756-.589-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<path d = "M173.862,61.593c-.882,1.064-1.891,1.583-2.325,1.583-.308,0-.588-.196-.35-1.387l.196-.952h-.028c-.505.714-1.064,1.261-1.527,1.652-.434.364-.98.687-1.442.687-.477,0-.925-.561-.925-1.667,0-1.358.645-2.815,1.597-3.655.673-.575,1.808-1.177,2.872-1.177.28,0,.574.027.883.098.279.056.504.098.714.098l.07.057c-.28,1.078-.645,2.731-.938,4.426-.084.448-.057.575.028.575.126,0,.616-.351.966-.701l.21.364ZM171.565,59.731c.182-.645.42-1.766.49-2.199-.182-.099-.546-.196-.854-.196-.294,0-.546.084-.742.238-.91.658-1.401,2.367-1.401,3.628,0,.588.127.84.322.84.505,0,1.863-1.639,2.186-2.311Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<g >
													  <path d = "M181.142,61.734c-.98,1.022-1.807,1.442-2.255,1.442-.379,0-.617-.322-.351-1.484.238-.995.477-1.989.7-2.984.183-.742.112-.924-.07-.924-.518,0-1.919,1.555-2.479,2.549-.196.729-.406,1.667-.574,2.55-.477.056-1.023.168-1.387.294l-.099-.126c.351-1.457.729-3.025,1.079-4.622.098-.435.084-.547-.015-.547-.126,0-.588.28-.896.589l-.196-.393c.896-.91,1.766-1.4,2.241-1.4.364,0,.505.434.225,1.694l-.21.883h.027c.771-1.051,1.625-1.779,2.228-2.186.393-.252.771-.392,1.079-.392.434,0,.854.237.434,2.101-.21.869-.406,1.766-.616,2.662-.084.35-.07.504.042.504s.519-.267.868-.603l.225.393Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M188.492,61.664c-.854,1.036-1.862,1.513-2.283,1.513-.336,0-.588-.21-.35-1.331l.196-1.008h-.028c-.561.742-1.177,1.316-1.695,1.723-.476.378-.938.616-1.358.616-.561,0-1.037-.588-1.037-1.736,0-1.331.603-2.816,1.611-3.671.687-.574,1.891-1.092,2.521-1.092.238,0,.448,0,.784.111l.406-2.059c.126-.616.042-.687-.588-.687l-.322-.014.069-.421c1.331-.126,2.172-.49,2.424-.49.154,0,.182.112.098.448-.63,2.731-1.148,5.253-1.625,7.845-.084.392-.069.532.043.532.14,0,.546-.267.924-.645l.21.364ZM186.237,59.716l.42-2.017c-.252-.238-.7-.393-1.05-.393-.309,0-.519.07-.715.225-.883.644-1.373,2.507-1.373,3.613,0,.603.183.896.406.896.616,0,1.863-1.513,2.312-2.325Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<g >
													  <path d = "M200.282,60.613c-.267.673-.799,1.737-1.135,2.256-.435.111-1.387.35-2.438.35-2.424,0-4.202-1.4-4.202-4.006,0-3.39,2.703-5.52,5.967-5.52,1.219,0,2.06.295,2.424.421-.028.729-.084,1.513-.141,2.283l-.462.042c-.196-1.695-.925-2.186-2.129-2.186-2.144,0-3.839,2.255-3.839,4.902,0,2.102.953,3.502,2.521,3.502,1.191,0,2.046-.826,2.998-2.241l.435.196Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M201.103,60.921c0-1.835,1.008-3.18,2.101-3.754.561-.295,1.177-.49,1.724-.49,1.303,0,2.003.854,2.003,2.213,0,1.219-.49,2.535-1.499,3.39-.603.519-1.471.896-2.312.896-1.555,0-2.017-1.162-2.017-2.255ZM204.044,62.504c.616-.253,1.303-1.737,1.303-3.656-.014-1.093-.406-1.583-.938-1.583-.126,0-.28.028-.406.084-.435.196-1.316,1.373-1.316,3.614,0,1.064.434,1.61.966,1.61.168,0,.267-.027.393-.069Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M217.866,61.734c-.854.966-1.82,1.442-2.241,1.442-.406,0-.602-.336-.35-1.415l.742-3.151c.14-.574.084-.826-.084-.826-.519,0-1.933,1.61-2.424,2.507-.182.771-.392,1.724-.56,2.605-.505.042-1.037.154-1.415.28l-.098-.126c.336-1.4.672-2.773.966-4.09.183-.854.168-1.177-.056-1.177-.546,0-1.989,1.681-2.409,2.521-.21.686-.435,1.694-.616,2.591-.477.042-.995.154-1.387.28l-.112-.126c.42-1.541.771-3.04,1.12-4.637.099-.406.084-.532-.014-.532-.154,0-.616.336-.91.603l-.183-.378c.869-.896,1.766-1.429,2.241-1.429.435,0,.49.518.21,1.667l-.21.896h.028c.938-1.275,1.835-1.976,2.312-2.256.35-.21.644-.308.938-.308.392,0,.84.252.448,2.073l-.099.434h.028c.938-1.246,1.807-1.919,2.325-2.213.364-.21.631-.294.91-.294.351,0,.854.195.406,2.087l-.63,2.745c-.084.351-.042.448.042.448.126,0,.561-.294.883-.603l.195.379Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M221.585,56.845c.435-.126.854-.168,1.275-.168,1.471,0,2.059.728,2.059,2.045,0,.868-.393,1.961-1.177,2.829-.687.771-1.736,1.401-2.689,1.625-.238,0-.476-.028-.756-.126l-.351,1.821c-.14.729-.042.854.645.938l.322.027-.07.435-3.614.168.099-.42c.826-.168,1.009-.322,1.19-1.163.504-2.395.994-4.734,1.471-7.031-.435.14-.798.308-1.106.476l-.126-.405c.435-.295.91-.505,1.358-.687l.154-.616c.309-.322.967-.743,1.415-.953l.153.099-.252,1.106ZM220.521,62.083c.141.196.477.463.757.448.854,0,2.059-1.933,2.059-3.697,0-1.177-.574-1.472-1.232-1.472-.224,0-.448.042-.658.099l-.925,4.622Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M232.354,61.664c-.911,1.022-1.808,1.513-2.283,1.513-.406,0-.561-.42-.322-1.457.07-.35.14-.658.21-.994h-.028c-.826,1.106-1.652,1.793-2.157,2.115-.336.224-.714.336-1.036.336-.406,0-.91-.266-.519-1.947.21-.924.435-1.849.658-2.801.099-.406.099-.574-.014-.574s-.504.308-.896.616l-.168-.393c.868-.841,1.807-1.4,2.297-1.4.379,0,.477.322.238,1.358-.224,1.009-.462,2.073-.7,3.082-.154.658-.084.896.126.896.687,0,2.003-1.695,2.382-2.396.195-.812.392-1.695.574-2.563l1.345-.378h.168c-.364,1.442-.715,2.955-1.051,4.664-.084.462-.07.589.028.589.126,0,.588-.295.938-.645l.21.378Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M237.126,57.461l-1.331.07-.757,3.655c-.14.645-.069.827.07.827.21,0,.841-.421,1.19-.757l.211.336c-.505.673-1.695,1.583-2.466,1.583-.448,0-.742-.462-.435-1.933l.799-3.712h-1.022l-.056-.141.378-.49h.84l.309-1.303,1.429-.967.154.112-.49,2.157h1.568l-.392.561Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M242.222,61.482c-1.246,1.316-2.311,1.694-3.18,1.694-.756,0-1.652-.42-1.652-2.087,0-1.303.588-2.731,1.652-3.558.574-.448,1.457-.854,2.157-.854.896,0,1.373.546,1.373,1.177,0,1.414-2.255,2.129-3.643,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.561,0,1.345-.406,2.018-1.05l.266.364ZM240.135,57.448c-.336.21-.938.938-1.148,2.409,1.681-.49,2.228-1.093,2.228-1.808,0-.532-.337-.756-.589-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<path d = "M242.976,58.106c.784-.827,1.694-1.429,2.171-1.429.406,0,.49.518.252,1.624l-.126.645h.056c.673-1.288,1.429-2.269,2.031-2.269.252,0,.505.14.645.364.154.224.126.504-.042.77-.154.238-.294.406-.448.519-.126.099-.238.099-.336.015-.183-.169-.364-.253-.532-.253-.154,0-.322.084-.617.49-.336.477-.714,1.135-.868,1.611-.182.812-.392,1.877-.532,2.703-.448.042-1.009.154-1.387.28l-.098-.126c.35-1.471.687-3.012.966-4.511.084-.462.07-.644-.027-.644-.126,0-.603.279-.911.574l-.195-.364Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<g >
													  <path d = "M258.604,53.917c-.042.336-.154,1.429-.252,2.171l-.477.042c-.028-.561-.07-1.022-.238-1.274-.168-.28-.406-.393-1.274-.393h-.841c-.504,0-.561.057-.658.574l-.561,2.9h1.093c1.177,0,1.232-.084,1.611-1.023h.476l-.56,2.802h-.463c.015-1.051.015-1.148-1.177-1.148h-1.092l-.463,2.409c-.126.687-.126,1.051.042,1.219.168.21.561.267,1.261.267.925,0,1.358-.126,1.723-.477.309-.294.659-.771.967-1.345l.448.168c-.154.462-.645,1.723-.854,2.199h-6.724l.084-.435c1.093-.098,1.19-.182,1.415-1.316l1.093-5.603c.196-1.093.126-1.205-.995-1.303l.126-.435h6.29Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M265.561,61.734c-.98,1.022-1.808,1.442-2.256,1.442-.378,0-.616-.322-.35-1.484.238-.995.476-1.989.7-2.984.182-.742.112-.924-.07-.924-.518,0-1.919,1.555-2.479,2.549-.195.729-.406,1.667-.574,2.55-.476.056-1.022.168-1.387.294l-.098-.126c.35-1.457.729-3.025,1.078-4.622.099-.435.084-.547-.014-.547-.126,0-.588.28-.896.589l-.196-.393c.896-.91,1.765-1.4,2.241-1.4.364,0,.505.434.225,1.694l-.21.883h.027c.771-1.051,1.625-1.779,2.228-2.186.392-.252.771-.392,1.078-.392.435,0,.854.237.435,2.101-.21.869-.406,1.766-.616,2.662-.084.35-.07.504.042.504s.519-.267.868-.603l.225.393Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M272.87,57.027c-.477.505-.854.7-1.121.742-.084.015-.154.028-.28.042.07.196.099.421.099.687,0,.925-.519,1.611-1.009,2.018-.406.336-1.079.658-1.989.868-.112.084-.196.196-.21.322,0,.182.308.393.98.742,1.442.7,2.003,1.261,2.003,1.905,0,1.219-2.059,2.144-3.544,2.144-1.442,0-2.591-.701-2.591-1.569.014-.658.896-1.232,2.297-1.905-.309-.195-.519-.476-.504-.7,0-.28.279-.561.994-.952-.938-.183-1.358-.799-1.358-1.695,0-.868.434-1.736,1.204-2.297.589-.435,1.358-.7,1.947-.7.532,0,.938.126,1.289.392.868-.014,1.414-.196,1.652-.336l.141.294ZM269.997,64.914c0-.337-.28-.631-.896-.995-.448-.266-.868-.49-1.19-.63-.658.35-1.177.868-1.177,1.373,0,.672.841,1.19,1.723,1.19.953,0,1.541-.435,1.541-.938ZM270.165,58.372c0-.672-.224-1.177-.714-1.177-.393,0-.729.379-.938.799-.238.462-.421,1.078-.421,1.737s.267,1.064.687,1.064c.658,0,1.387-.938,1.387-2.424Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M275.485,61.692c-.798.896-1.862,1.484-2.311,1.484-.364,0-.631-.266-.337-1.457l.785-3.306c.069-.336.084-.476-.015-.476-.111,0-.518.237-.882.532l-.183-.364c.925-.869,1.934-1.429,2.396-1.429.364,0,.42.504.224,1.288l-.854,3.46c-.084.378-.056.519.043.519.14,0,.532-.267.924-.589l.21.337ZM275.738,54.688c0,.519-.406.98-.953.98-.434,0-.7-.294-.7-.771-.014-.406.322-.967.938-.967.477,0,.715.351.715.757Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M282.935,61.734c-.98,1.022-1.808,1.442-2.256,1.442-.378,0-.616-.322-.35-1.484.238-.995.476-1.989.7-2.984.182-.742.112-.924-.07-.924-.519,0-1.919,1.555-2.479,2.549-.195.729-.406,1.667-.574,2.55-.476.056-1.022.168-1.387.294l-.098-.126c.35-1.457.729-3.025,1.078-4.622.099-.435.084-.547-.014-.547-.126,0-.588.28-.896.589l-.196-.393c.896-.91,1.765-1.4,2.241-1.4.364,0,.505.434.225,1.694l-.211.883h.028c.771-1.051,1.625-1.779,2.228-2.186.392-.252.771-.392,1.078-.392.435,0,.854.237.435,2.101-.21.869-.406,1.766-.616,2.662-.084.35-.07.504.042.504s.519-.267.868-.603l.225.393Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M288.589,61.482c-1.247,1.316-2.312,1.694-3.18,1.694-.757,0-1.653-.42-1.653-2.087,0-1.303.589-2.731,1.653-3.558.574-.448,1.457-.854,2.157-.854.896,0,1.373.546,1.373,1.177,0,1.414-2.256,2.129-3.643,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.561,0,1.345-.406,2.017-1.05l.267.364ZM286.502,57.448c-.336.21-.938.938-1.148,2.409,1.681-.49,2.227-1.093,2.227-1.808,0-.532-.336-.756-.588-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M294.232,61.482c-1.247,1.316-2.312,1.694-3.18,1.694-.757,0-1.653-.42-1.653-2.087,0-1.303.589-2.731,1.653-3.558.574-.448,1.457-.854,2.157-.854.896,0,1.373.546,1.373,1.177,0,1.414-2.256,2.129-3.643,2.493-.014.168-.014.294-.014.406,0,.729.252,1.414,1.022,1.414.561,0,1.345-.406,2.017-1.05l.267.364ZM292.145,57.448c-.336.21-.938.938-1.148,2.409,1.681-.49,2.227-1.093,2.227-1.808,0-.532-.336-.756-.588-.756-.154,0-.351.056-.49.154Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<path d = "M294.985,58.106c.784-.827,1.694-1.429,2.171-1.429.406,0,.49.518.252,1.624l-.126.645h.057c.672-1.288,1.429-2.269,2.03-2.269.253,0,.505.14.645.364.154.224.126.504-.042.77-.154.238-.294.406-.448.519-.126.099-.238.099-.336.015-.183-.169-.364-.253-.532-.253-.154,0-.322.084-.616.49-.337.477-.715,1.135-.869,1.611-.182.812-.392,1.877-.532,2.703-.448.042-1.008.154-1.387.28l-.098-.126c.351-1.471.687-3.012.967-4.511.084-.462.069-.644-.028-.644-.126,0-.603.279-.91.574l-.196-.364Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
												<g >
													  <path d = "M303.218,61.692c-.798.896-1.862,1.484-2.311,1.484-.364,0-.631-.266-.337-1.457l.785-3.306c.069-.336.084-.476-.015-.476-.111,0-.518.237-.882.532l-.183-.364c.925-.869,1.934-1.429,2.396-1.429.364,0,.42.504.224,1.288l-.854,3.46c-.085.378-.057.519.042.519.14,0,.532-.267.924-.589l.21.337ZM303.471,54.688c0,.519-.406.98-.953.98-.434,0-.7-.294-.7-.771-.014-.406.322-.967.938-.967.477,0,.715.351.715.757Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
													  <path d = "M310.668,61.734c-.98,1.022-1.808,1.442-2.256,1.442-.378,0-.616-.322-.35-1.484.238-.995.476-1.989.7-2.984.182-.742.112-.924-.07-.924-.518,0-1.919,1.555-2.479,2.549-.195.729-.406,1.667-.574,2.55-.476.056-1.022.168-1.387.294l-.098-.126c.35-1.457.729-3.025,1.078-4.622.099-.435.084-.547-.014-.547-.126,0-.588.28-.896.589l-.196-.393c.896-.91,1.765-1.4,2.241-1.4.364,0,.505.434.225,1.694l-.21.883h.027c.771-1.051,1.625-1.779,2.228-2.186.392-.252.771-.392,1.078-.392.435,0,.854.237.435,2.101-.21.869-.406,1.766-.616,2.662-.084.35-.07.504.042.504s.519-.267.868-.603l.225.393Z"
															fill = "rgb(var(--v-theme-on-background), 1)"
															stroke-width = "0" />
												</g >
												<path d = "M317.949,57.027c-.477.505-.854.7-1.121.742-.084.015-.154.028-.28.042.07.196.099.421.099.687,0,.925-.519,1.611-1.009,2.018-.406.336-1.079.658-1.989.868-.112.084-.196.196-.21.322,0,.182.308.393.98.742,1.442.7,2.003,1.261,2.003,1.905,0,1.219-2.059,2.144-3.544,2.144-1.442,0-2.591-.701-2.591-1.569.014-.658.896-1.232,2.297-1.905-.309-.195-.519-.476-.504-.7,0-.28.279-.561.994-.952-.938-.183-1.358-.799-1.358-1.695,0-.868.434-1.736,1.204-2.297.589-.435,1.359-.7,1.947-.7.532,0,.938.126,1.289.392.868-.014,1.414-.196,1.652-.336l.141.294ZM315.077,64.914c0-.337-.28-.631-.896-.995-.448-.266-.868-.49-1.19-.63-.658.35-1.177.868-1.177,1.373,0,.672.841,1.19,1.723,1.19.953,0,1.541-.435,1.541-.938ZM315.245,58.372c0-.672-.224-1.177-.714-1.177-.393,0-.729.379-.938.799-.238.462-.421,1.078-.421,1.737s.267,1.064.687,1.064c.658,0,1.387-.938,1.387-2.424Z"
													  fill = "rgb(var(--v-theme-on-background), 1)"
													  stroke-width = "0" />
										  </g >
										  <rect x = "67.805" y = "25.644" width = "259.784" height = ".403"
												fill = "rgb(var(--v-theme-on-background), 1)"
												stroke-width = "0" />
									</g >
									<g id = "MiamiUniversity" >
										  <path d = "M189.216.376h-6.717l.035.171c.653.276,2.043.708,2.32,1.035.326.45.301,1.028.301,1.43v9.362c0,3.764-2.962,6.048-5.974,5.621-2.862-.426-4.869-1.932-4.869-5.923V2.836c0-.552,0-.954.326-1.38.351-.402,1.619-.641,2.321-.918l.009-.162h-7.257l.021.176c.678.175,1.944.577,2.195.904.352.451.251,1.054.251,1.455v9.061c0,5.195,3.414,7.329,7.078,7.354,3.815.025,7.329-1.907,7.329-7.656V3.815c0-.302.076-1.883.352-2.233.3-.403,1.603-.701,2.281-1.027l-.002-.179Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M242.245.376h-6.167l.038.169c.66.23,1.778.71,1.928,1.137.076.175-.075.903-.276,1.455l-4.643,12.625-4.995-12.6c-.15-.452-.426-1.255-.351-1.556.15-.502,1.355-.864,1.883-1.064l.033-.166h-6.568l.017.174c.703.377,1.849.705,2.1,1.181.226.453.326.753.527,1.23.627,1.506,4.718,11.371,5.798,14.658.176.627.402.954.653,1.581h1.279c.227-.752.327-1.205.553-1.932.452-1.456,4.568-13.177,5.22-14.482.176-.477.377-.779.628-1.204.251-.478,1.646-.761,2.349-1.037l-.006-.169Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M255.834,15.44l-.234.016c-.326.754-.594,1.963-1.021,2.189-.376.15-.677.075-1.079.075h-4.116c-.477,0-.803.075-1.255-.126-.326-.15-.427-.903-.427-1.305v-6.174h3.69c.552,0,.904-.025,1.405.251.502.276.677,1.179.929,1.857l.226.021v-5.349l-.216.006-.311,1.08c-.126.276-.251.427-.502.628-.427.326-1.004.251-1.656.251h-3.565V2.836c0-.402-.025-.854.151-1.029.251-.251.753-.2,1.255-.2h3.087c.502,0,1.079.049,1.531.325.527.352.799,1.352,1.05,2.005l.18-.002.025-3.559h-12.018l.02.176c.678.176,2.084.577,2.335.904.326.426.25.828.25,1.38v13.579c0,.401.076,1.054-.25,1.456-.251.326-1.657.727-2.335.903l-.02.175h12.746l.125-3.509Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M275.395,19.151l-.677-.326c-1.33-.678-1.557-.878-2.385-1.682-1.33-1.255-2.108-2.209-3.388-3.639l-2.785-3.087.501-.101c1.531-.401,4.418-1.43,4.418-4.794s-2.586-5.02-4.845-5.17c-1.506-.126-2.509.025-3.864.025h-4.027l.011.201c.727.276,1.957.678,2.308,1.104.252.351.276.703.276,1.155v13.579c0,.401.051,1.054-.25,1.456-.227.325-1.657.727-2.334.903l-.021.175h7.395l.005-.226c-.704-.276-1.984-.451-2.335-.878-.326-.402-.326-.803-.326-1.33v-5.949h.677l3.665,3.89c1.682,1.808,3.489,3.991,4.643,4.419.979.35,1.983.576,2.51.476l.828-.201ZM269.071,5.196c.2,2.535-2.059,4.267-4.819,4.292l-1.18.025V2.711c0-.401-.025-.779.301-1.003.276-.176,1.355-.252,2.134-.201,1.631.076,3.388,1.48,3.564,3.689"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M285.649,14.056c-.076-3.062-2.786-4.242-5.498-5.623-1.079-.526-3.338-1.607-3.363-3.564,0-2.007,1.682-3.639,3.816-3.539,1.531.076,2.484.327,2.961,1.532l.847,2.14.208.002-.051-4.426-.502-.151c-.703-.201-2.033-.427-3.363-.427-2.987.025-5.873,1.982-5.723,5.246.176,3.062,2.058,3.79,4.644,5.22,2.058,1.13,4.041,1.782,4.191,4.066.126,2.159-2.033,3.615-4.241,3.364-1.307-.126-2.762-.477-3.288-1.932l-.677-1.653h-.177l.126,4.212,1.079.276c.552.127,1.882.478,3.087.478,3.489-.026,5.974-1.858,5.924-5.221"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M310.734,4.965V.373l-14.189.009-.017,4.484.26-.002c.377-.904.569-2.579,1.121-2.931.226-.126.426-.176.677-.176l3.891.05v14.609c0,.401.1,1.004-.251,1.456-.251.325-1.657.727-2.334.903l-.021.175h7.396l-.002-.179c-.704-.276-1.978-.497-2.329-.925-.326-.401-.326-.803-.326-1.33V1.807h3.313c.477,0,.779-.024,1.206.126.627.226,1.147,2.23,1.423,3.034h.182Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M212.207.376l.003.166c-.652.251-1.468.612-1.844.989-.378.376-.302,3.212-.302,3.212v14.257h-1.204c-2.636-2.955-2.954-3.318-3.289-3.664-.192-.197-.389-.389-1.029-1.055-3.965-4.191-7.928-9.086-9.56-10.642v10.868c0,.577-.126,2.51.251,3.288.226.401,1.251.813,1.914.973l.053.181h-5.716l.01-.2c.702-.301,1.581-.602,1.832-1.054.225-.427.225-2.084.225-3.163V2.33c-.022-.333-.094-.619-.322-.916-.353-.403-1.64-.683-2.359-.88l.009-.17,4.129.012c.375.427.802,1.104,1.655,1.958,4.292,4.392,10.816,12.173,11.87,13.127V4.793c-.025-1.255.076-2.786-.15-3.211-.251-.478-1.205-.704-1.883-1.005l-.01-.201h5.717Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M222.188,18.974l.006-.196c-.705-.277-1.987-.484-2.339-.911-.327-.402-.327-.805-.327-1.333V2.83c0-.554,0-.956.327-1.384.352-.402,1.647-.6,2.351-.876l-.009-.186h-7.404l.008.186c.678.176,2.087.549,2.338.876.352.453.252,1.057.252,1.459v13.529c0,.402.1,1.005-.252,1.458-.251.327-1.66.729-2.338.905l-.023.177h7.41Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M317.224,2.886l2.987,5.428,2.917-5.079c.377-.653.704-1.432.628-1.608-.151-.403-.692-.616-1.928-1.047l-.009-.186h6.363l-.023.186c-.73.277-1.662.393-2.442,1.097-.378.327-.603.88-.955,1.458l-3.721,6.336-.025,7.089c0,.552.025.93.352,1.332.327.428,1.772.637,2.475.914l.014.193h-7.668l.028-.193c.678-.176,2.185-.562,2.436-.889.352-.453.251-1.056.251-1.458v-6.26l-3.773-7.064c-.359-.578-.564-1.131-.941-1.458-.779-.704-1.713-.82-2.442-1.097l-.022-.186h6.996l-.007.186c-1.237.431-1.777.644-1.928,1.047-.062.145.149.702.437,1.259"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M294.918,18.974l.005-.196c-.704-.277-1.987-.484-2.339-.911-.327-.402-.327-.805-.327-1.333V2.83c0-.554,0-.956.327-1.384.352-.402,1.647-.6,2.352-.876l-.009-.186h-7.404l.007.186c.679.176,2.087.549,2.339.876.351.453.251,1.057.251,1.459v13.529c0,.402.1,1.005-.251,1.458-.252.327-1.66.729-2.339.905l-.023.177h7.411Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M103.647,19.148l.005-.197c-.711-.279-2.005-.488-2.359-.919-.331-.407-.331-.813-.331-1.345V2.857c0-.558,0-.963.331-1.395.354-.406,1.661-.605,2.372-.884l-.009-.188h-7.471l.007.188c.685.177,2.106.554,2.36.884.354.457.253,1.065.253,1.472v13.651c0,.406.101,1.015-.253,1.472-.254.33-1.675.736-2.36.913l-.024.178h7.479Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M70.414,2.955c-.027-.405.074-1.014-.281-1.471-.254-.33-1.675-.736-2.36-.913l-.007-.178h2.659l1.471-.022c.178.533.508,1.523.812,2.208,1.726,3.552,6.649,11.875,7.435,12.915,1.294-2.08,4.06-7.384,6.623-12.078.608-1.117.901-1.833,1.434-3.026h4.225l.005.21c-.71.279-2.004.456-2.36.862-.292.383-.323.746-.326,1.212.033.443.07.906.118,1.453.431,4.719,1.091,12.077,1.116,12.179.051.584.178,1.218.355,1.574.228.456,1.403.792,2.114,1.071l.005.197h-6.82l.031-.197c.66-.228,1.727-.539,1.955-.945.533-.888.279-1.522.229-2.106l-1.041-11.494c-1.04,1.776-4.568,8.247-6.344,11.545-.684,1.294-1.522,3.146-1.648,3.4-.178-.354-.889-1.624-1.422-2.613-3.73-6.903-6.267-10.911-6.749-11.749l-.711,9.719c-.05.583-.356,2.41.152,3.298.254.406,1.187.666,1.846.945l.014.197h-5.634l.019-.203c.71-.305,1.674-.583,1.928-1.04.228-.431.305-1.852.356-3.045.431-3.527.583-8.45.735-10.48.035-.521.054-.985.066-1.425"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M128.15,2.955c-.027-.405.074-1.014-.281-1.471-.254-.33-1.675-.736-2.36-.913l-.007-.178h2.659l1.472-.022c.177.533.508,1.523.812,2.208,1.725,3.552,6.648,11.875,7.434,12.915,1.295-2.08,4.06-7.384,6.623-12.078.609-1.117.902-1.833,1.434-3.026h4.225l.006.21c-.71.279-2.005.456-2.36.862-.293.383-.324.746-.327,1.212.033.443.07.906.118,1.453.431,4.719,1.091,12.077,1.116,12.179.051.584.178,1.218.356,1.574.228.456,1.403.792,2.113,1.071l.006.197h-6.821l.032-.197c.659-.228,1.726-.539,1.955-.945.532-.888.279-1.522.228-2.106l-1.04-11.494c-1.041,1.776-4.568,8.247-6.345,11.545-.684,1.294-1.522,3.146-1.649,3.4-.177-.354-.888-1.624-1.42-2.613-3.731-6.903-6.267-10.911-6.75-11.749l-.71,9.719c-.051.583-.355,2.41.152,3.298.253.406,1.186.666,1.846.945l.013.197h-5.634l.019-.203c.711-.305,1.676-.583,1.929-1.04.229-.431.304-1.852.355-3.045.431-3.527.584-8.45.736-10.48.034-.521.054-.985.065-1.425"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M161.384,19.148l.005-.197c-.71-.279-2.005-.488-2.36-.919-.33-.407-.33-.813-.33-1.345V2.857c0-.558,0-.963.33-1.395.355-.406,1.662-.605,2.373-.884l-.009-.188h-7.471l.007.188c.686.177,2.106.554,2.36.884.355.457.253,1.065.253,1.472v13.651c0,.406.102,1.015-.253,1.472-.254.33-1.674.736-2.36.913l-.024.178h7.479Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
										  <path d = "M116.466,10.293h-5.152l2.589-6.649,2.563,6.649ZM123.761,19.148l-.004-.197c-.736-.33-1.836-.665-2.09-1.148-.228-.456-.406-.761-.584-1.243-.684-1.497-4.465-11.52-5.531-14.844-.152-.507-.228-.837-.38-1.345h-1.7c-.153.66-.254,1.066-.457,1.726-.457,1.445-3.35,9.159-5.709,14.513-.178.483-.305.813-.533,1.245-.254.481-1.421.811-2.132,1.09l.006.189,5.787.014-.016-.167c-.66-.279-1.615-.543-1.793-.975-.051-.177.178-1.116.381-1.649l1.776-4.745h6.267l1.7,4.745c.178.432.457,1.243.381,1.523-.152.532-1.251.843-1.784,1.071l-.025.197h6.44Z"
												fill = "rgb(var(--v-theme-on-background), 1)" stroke-width = "0" />
									</g >
									<g id = "M" >
										  <g id = "M-group" >
												<polygon
													  points = "48.245 29.735 48.245 11.745 49.062 11.745 56.199 0 36.276 0 28.099 13.604 19.922 0 0 0 7.136 11.745 7.954 11.745 7.954 29.735 7.136 29.735 0 41.48 56.199 41.48 49.062 29.735 48.245 29.735"
													  fill = "#fff" stroke-width = "0" />
												<polygon
													  points = "28.917 40.291 34.567 30.999 35.905 30.999 35.905 24.457 28.099 37.392 20.294 24.457 20.294 30.999 21.632 30.999 27.282 40.291 2.156 40.291 7.805 30.999 9.143 30.999 9.143 10.556 7.805 10.556 2.156 1.19 19.179 1.19 28.099 15.908 37.02 1.19 54.043 1.19 48.393 10.556 46.981 10.556 46.981 30.999 48.393 30.999 54.043 40.291 28.917 40.291"
													  fill = "#231f20" stroke-width = "0" />
												<polygon
													  points = "11.597 8.103 9.218 8.103 6.542 3.643 17.841 3.643 17.841 3.643 28.099 20.666 38.358 3.643 49.657 3.643 46.981 8.103 44.602 8.103 44.602 33.378 46.981 33.378 49.657 37.838 33.229 37.838 35.979 33.378 38.358 33.378 38.358 15.685 31.742 26.687 28.099 32.708 28.099 32.708 28.099 32.708 24.457 26.687 17.841 15.685 17.841 33.378 20.22 33.378 22.97 37.838 6.542 37.838 9.218 33.378 11.597 33.378 11.597 8.103"
													  fill = "#c41230" stroke-width = "0" />
										  </g >
									</g >
							  </svg >
						</v-card-item >

						<v-card-title
							  class = "robot-crush text-center align-center" >
						</v-card-title >
						<v-card-text class = "text-center align-center" >
							  <div class = "text-h6" ></div >
							  <div class = "text-caption" ></div >
						</v-card-text >

				  </v-card >

			</v-container >
			<template v-slot:opposite >
				  <div
						class = "bp-year"

				  >2011 - 2016
				  </div >
			</template >
			<template v-slot:icon >
			</template >
	  </v-timeline-item >
</template >
<script >
export default {
	name: "miami-timeline-item"
}
</script >