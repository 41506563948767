<template >
	  <div class = "seDefault" >
									<span
										  class = "Comment" >//The primary motivation for me to learn programming was my interest</span ><br >
			<span class = "Comment" >//in Autoing in RuneScape.</span ><br >
			<br >
			<span class = "Comment" >//SCAR is an IDE for writing scripts that automate tasks in</span ><br >
			<span class = "Comment" >//video games like clicking on colors or images on the screen.</span ><br >
			<span class = "Comment" >//SCAR's syntax was inspired by Pascal.</span ><br ><br >
			<span
				  class = "Comment" >//The first script I ever wrote was called script I wrote was called</span ><br >
			<br >
			<span class = "Reserved_word" >program</span > &nbsp;<span
			class = "Identifier" >briansFeatherBuyer</span ><span
			class = "Symbol" >;</span ><br >
			<span class = "Reserved_word" >begin</span ><br >
			&nbsp;<span class = "Identifier" >WriteLn</span ><span
			class = "Symbol" >(</span ><span
			class = "String" >'Hello World!'</span ><span
			class = "Symbol" >)</span ><span
			class = "Symbol" >;</span ><br >
			<span class = "Reserved_word" >end</span ><span
			class = "Symbol" >.</span ></div >
</template >
<script >
export default {
	name: "scar-text"
}
</script >